.range-slider {
  &__range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 400px;
    margin: 0 auto;

    &::before {
      order: 2;
      content: '';
      width: 8px;
      height: 1px;
      background: transparent;
      display: block;
    }
  }

  &__current-value {
    width: calc(50% - 10px);
    display: block;
    height: 40px;
    line-height: 38px;
    cursor: pointer;
    border: 1px solid $borderDefaultColor;
    padding: 0 10px;
    text-align: center;
    font-size: $inputFieldFontSizeMobile;
    background: $rangeSliderInputBackground;
    color: $rangeSliderInputColor;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $rangeSliderFontSize;
    }

    &--min {
      order: 1;
    }

    &--max {
      order: 3;
    }
  }

  &__current-value-edit {
    width: calc(50% - 10px);
    padding: 0 10px;
    height: 42px;
    border: $rangeSliderInputBorderActive;
    background: $rangeSliderInputBackground;
    text-align: center;
    font-size: $inputFieldFontSizeMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $rangeSliderFontSize;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: $inputShadow;
      border: $rangeSliderInputBorderActive;
    }

    &--min {
      order: 1;
    }

    &--max {
      order: 3;
    }
  }

  &__slider {
    width: calc(100% - 20px);
    margin: 0 10px;
    direction: ltr;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 10px;
      height: 3px;
      background: $rangeSliderOuterLineColor;
      position: absolute;
      top: 20px;
    }

    &::before {
      left: 100%;
    }

    &::after {
      right: 100%;
    }

    .noUi-base { // stylelint-disable-line
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 20px 0;
      cursor: pointer;
    }

    .noUi-connects { // stylelint-disable-line
      width: 100%;
      height: 3px;
      background: $rangeSliderLineColor;
      position: relative;
      overflow: hidden;
    }

    .noUi-connect { // stylelint-disable-line
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform-origin: 0 0;
      background: lighten($rangeSliderHighlightColor, 10%);
    }

    .noUi-origin { // stylelint-disable-line
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      left: auto;
      right: 0;
      height: 0;
      width: 100%;
      transform-origin: 0 0;
    }

    .noUi-handle { // stylelint-disable-line
      position: absolute;
      width: 22px;
      height: 22px;
      right: -11px;
      top: 22px;
      margin-top: -11px;
      background: $rangeSliderHandleBackground;
      border: $rangeSliderHandleBorder;
      border-radius: $rangeSliderHandleRadius;
      transition: transform .2s;
      cursor: grab; // stylelint-disable-line plugin/no-unsupported-browser-features
    }

    .noUi-active { // stylelint-disable-line
      transform: scale(1.2);
      cursor: grabbing; // stylelint-disable-line plugin/no-unsupported-browser-features
    }
  }
}
