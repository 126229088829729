@import '../../../bps/components/treeNavigation/treeNavigation.scss';

.tree-navigation {
  $root: &;

  &--long & &--last-level #{$root}__list {
    @include customScrollbar(4px);
    overflow-y: auto;
    max-height: 160px;
    padding-bottom: 20px;

    @media (min-width: $screen-tablet-portrait) {
      max-height: 444px;
    }
  }

  .button--secondary-small {
    margin: 5px 0;
    font-size: $fontSizeXSmall;
    line-height: 27px;

    &:hover {
      background: $buttonSecondaryHoverBackgroundColor;
    }
  }
}

// Placeholder style
/* stylelint-disable-next-line */
[data-placeholder="tree-navigation"] {
  .tree-navigation--last-level .tree-navigation__child-container {
    overflow: hidden;
    max-height: $treeNavigationL1ItemHeight * 12;
  }
}
