.products-list {
  $root: &;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: -$productsListSpaceMobile * .5;
  position: relative;

  @media (min-width: $screen-tablet-portrait) {
    margin: -$productsListSpaceTablet * .5;
  }

  @media (min-width: $screen-desktop) {
    margin: -$productsListSpaceDesktop * .5;
  }

  &--type_list {
    & > #{$root}__element {
      width: 100% !important;
      position: relative;

      &--type_insertion {
        width: calc(100% - #{$productsListSpaceMobile});

        @media (min-width: $screen-tablet-portrait) {
          width: calc(100% - #{$productsListSpaceTablet});
        }

        @media (min-width: $screen-desktop) {
          width: calc(100% - #{$productsListSpaceDesktop});
        }
      }
    }
  }

  &__loader ~ #{$root}__element,
  &--loading #{$root}__element {
    opacity: .3;
    pointer-events: none;
  }

  &__loader {
    position: absolute;
    top: 30px;
    background: $whiteColor;
    border-radius: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-left: -25px;
    z-index: 2;
  }

  &__element {
    &--type {
      &_product {
        width: 100%;
        padding: $productsListSpaceMobile * .5;

        @media (min-width: $screen-tablet-xsm) {
          width: 50%;
        }

        @media (min-width: $screen-tablet-portrait) {
          padding: $productsListSpaceTablet * .5;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: calc(100% / 3);
        }

        .product-list--max-list-columns-4 & {
          @media (min-width: $screen-desktop-xxl) {
            width: 25%;
          }
        }

        @media (min-width: $screen-desktop) {
          padding: $productsListSpaceDesktop * .5;

          .inspiration-list--themeplanning & {
            width: 25%;
          }
        }

        .page-home &,
        .landing-page &,
        #{$root}--no-sidebar & {
          @media (min-width: $screen-tablet-portrait) {
            width: calc(100% / 3);
          }

          @media (min-width: $screen-desktop) {
            width: 25%;
          }
        }
      }

      &_product-wishlist {
        @extend #{$root}__element--type_product;

        @media (min-width: $screen-tablet-landscape-sm) {
          width: 25%;
          border: none;
        }
      }

      &_insertion {
        padding: $productsListInsertionPaddingMobile;
        margin: $productsListSpaceMobile * .5;
        border-top: $productsListInsertionBorderTop;
        border-bottom: $productsListInsertionBorderBottom;
        width: calc(100% - #{$productsListSpaceMobile});

        @media (min-width: $screen-tablet-portrait) {
          padding: $productsListInsertionPaddingTablet;
          margin: $productsListSpaceTablet * .5;
          width: calc(100% - #{$productsListSpaceTablet});
        }

        @media (min-width: $screen-desktop) {
          padding: $productsListInsertionPaddingDesktop;
          margin: $productsListSpaceDesktop * .5;
          width: calc(100% - #{$productsListSpaceDesktop});
        }

        .headline {
          margin: 0 0 20px;

          h1,
          h2,
          h3,
          h4,
          h5 {
            margin: $productsListInsertionHeadlineMargin;
            color: $productsListInsertionTextColor;
            font-weight: $productsListInsertionHeadlineFontWeight;
            padding: 0;
          }
        }

        .cinema-teaser {
          &__wrapper-outer {
            overflow: visible;
          }
        }

        .carousel__button {
          &--prev {
            @media (min-width: $screen-desktop-xl) {
              left: -20px;
            }
          }

          &--next {
            @media (min-width: $screen-desktop-xl) {
              right: -20px;
            }
          }
        }

        .products-list-page:not(.products-list-page--no-sidebar) & {
          .teasergroup_teaser { // stylelint-disable-line
            &.teasergroup_teaser--float { // stylelint-disable-line
              .teasergroup_teaser__items-container { // stylelint-disable-line
                @media (min-width: $screen-tablet-portrait) {
                  width: calc(100% + 12px);
                  margin: -6px;
                }
              }

              .teasergroup_teaser__item {
                @media (min-width: $screen-tablet-portrait) {
                  padding: 6px;
                }
              }
            }
          }
        }
      }
    }

    &--size {
      &_25 {
        @media (min-width: $screen-tablet-landscape-sm) {
          width: 25%;
          border: none;
        }
      }
    }
  }

  &__product-box.product-box {
    padding: $productBoxListPadding;
    border: $productBoxListBorder;
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
products-list,
[is="products-list"] {
  display: none;
}
