.sidebar-menu {
  $root: &;
  position: relative;
  background: $whiteColor;

  @media (min-width: $screen-tablet-portrait) {
    background: transparent;
  }

  &__header {
    @include iconFont('arrowDownBold', 'after') {
      font-size: 10px;
      transition: transform .2s;
      position: absolute;
      top: 50%;
      right: 16px;
      font-weight: bold;
      transform: translateY(-50%);
    }
    border: $borderDarkColor 1px solid;
    position: relative;
    font-size: $baseFontSize;
    padding: 12px 16px;
    font-weight: $fontWeightBold;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }

    &--open {
      &::after {
        transform: translateY(-50%) scaleY(-1);
      }
    }
  }

  &__content {
    display: none;
    border: $borderDarkColor 1px solid;
    border-top: 0;
    padding: 10px;
    margin-bottom: 20px;

    @media (min-width: $screen-tablet-portrait) {
      border: none;
      display: block;
      padding: 0 0 40px;
    }

    &--open {
      display: block;
    }
  }

  &__list {
    @include clearList();
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 0 20px;
    }
  }

  &__item {
    display: block;
    color: $sidebarMenuColor;
    font-size: $sidebarMenuFontSize;
    font-weight: $sidebarMenuFontWeight;

    @media (min-width: $screen-tablet-portrait) {
      &:not(:last-child) {
        border-bottom: $sidebarMenuBorderBottom;
      }
    }

    &--selected {
      #{$root}__link {
        color: $sidebarMenuSelectedColor;
        font-weight: $sidebarMenuSelectedFontWeight;
        cursor: default;
      }
    }

    &--mobile-hidden {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        display: block;
      }
    }
  }

  &__link {
    display: block;
    color: $sidebarMenuLinkColor;
    font-weight: $sidebarMenuFontWeight;
    text-decoration: none;
    line-height: 1.3;
    position: relative;
    padding: 14px 0;

    @media (min-width: $screen-tablet-portrait) {
      padding: 8px 0;
    }

    &:visited {
      color: $sidebarMenuLinkColor;
    }

    &:hover,
    &--active {
      color: $sidebarMenuSelectedColor;

      &:visited {
        color: $sidebarMenuSelectedColor;
      }
    }

    &--active {
      font-weight: $sidebarMenuSelectedFontWeight;
    }
  }

  &__link-big {
    color: $textDarkestColor;
    font-size: $fontSizeXXXMedium;
    font-weight: $fontWeightRegular;
    padding: 0 0 10px;
  }

  &__sub-link {
    display: block;
    color: $textDarkestColor;
    font-weight: $fontWeightRegular;
    text-decoration: none;
    line-height: 1.3;
    position: relative;
    padding: 14px 0;

    @media (min-width: $screen-tablet-portrait) {
      padding: 10px 0;
    }

    &:not(:last-child) {
      border-bottom: $grayLightColor 1px solid;
    }

    &:visited {
      color: $textDarkestColor;
    }

    &:hover,
    &--active {
      color: $brandPrimaryColor;

      &:visited {
        color: $brandPrimaryColor;
      }
    }
  }

  &__sub-link-group {
    position: relative;
    padding: 0 0 0 20px;
  }

  &__link-addition {
    font-weight: $fontWeightThin;
    margin-left: 5px;
  }
}

// Placeholder style
/* stylelint-disable-next-line */
sidebar-menu.sidebar-menu {
  width: 100%;

  @media (max-width: $screen-tablet-portrait - 1px) {
    display: none;
  }
}

.sidebar-menu--placeholder {
  display: none;
  height: 42px;

  @media (max-width: $screen-tablet-portrait - 1px) {
    display: block;
  }
}
