.tree-navigation {
  $root: &;

  &--last-level {
    #{$root}__child-container {
      @include customScrollbar();
      overflow-y: auto;
    }

    &#{$root}--level_1 {
      #{$root}__child-container {
        max-height: $treeNavigationL1ItemHeight * 12;
      }
    }

    &#{$root}--level_2 {
      #{$root}__child-container {
        max-height: $treeNavigationL2ItemHeight * 12;
      }
    }

    &#{$root}--level_3,
    &#{$root}--level_4 {
      #{$root}__child-container {
        max-height: $treeNavigationL3ItemHeight * 12;
      }

      .filter__search {
        width: calc(100% + #{$treeNavigationPaddingLeft});
        margin-left: -$treeNavigationPaddingLeft;
      }
    }
  }

  &__child-container {
    margin: $treeNavigationMargin;
  }

  &__list {
    @include clearList();
  }

  &--level_2 {
    #{$root}__item {
      border-left: $treeNavigationBorderLeft;
      padding-left: $treeNavigationPaddingLeft;

      &--has-children {
        & > #{$root}__label {
          @include iconFont($treeNavigationIconDown, after) {
            display: inline-block;
            font-weight: bold;
            font-size: $treeNavigationFontSizeChildren;
            transition: transform .2s;
            margin-left: 8px;
          }

          &::after {
            .theme-navigation & {
              display: none;
            }
          }
        }
      }

      &--open {
        & > #{$root}__label::after {
          transform: scaleY(-1);
        }
      }
    }
  }

  &--level_3 {
    padding-left: $treeNavigationPaddingLeft;

    #{$root}__item {
      border-left: 0;
      padding-left: 0;
      font-size: $treeNavigationFontSizeSecondary;
      color: $treeNavigationTextColor;
      font-weight: normal;
    }
  }

  &--level_4 {
    padding-left: $treeNavigationPaddingLeft;
  }

  &__item {
    position: relative;
    color: $treeNavigationHighlightColor;
    font-size: $treeNavigationFontSize;
    font-weight: $treeNavigationFontWeight;
    line-height: 185%;
    cursor: pointer;
    padding: $treeNavigationItemPadding;

    &--active {
      color: $treeNavigationTextColor !important;
      font-weight: $treeNavigationFontWeightActive;
    }

    &--open {
      > #{$root}__label {
        font-weight: $treeNavigationFontWeightActive;
      }
    }
  }

  &--hide-nested {
    #{$root}__item {
      &--open {
        > #{$root}__label {
          font-weight: $treeNavigationFontWeight;
        }
      }

      &--active {
        > #{$root}__label {
          font-weight: $treeNavigationFontWeightActive;
        }
      }
    }
  }

  &__label {
    display: block;
    color: inherit;
    text-decoration: none;

    @media (min-width: $screen-tablet-portrait) {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;

      &::after {
        position: absolute;
        top: $treeNavigationIconTop;
        right: auto;
      }
    }
  }

  &__search {
    margin: 12px 0 8px;
    position: relative;
  }
}
