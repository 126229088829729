@import '../../../bps/components/filter/filter.scss';

.filter {
  &__search .input__field {
    font-weight: $fontWeightRegular;
  }

  &__option {
    margin-bottom: 18px;

    @media (min-width: $screen-desktop) {
      margin-bottom: 10px;
    }
  }
}
