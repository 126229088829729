/* stylelint-disable  plugin/no-unsupported-browser-features */

$mcs: 'hawesko';

// Colors
$blackColor: #000;
$grayDarkColor: #2f2f2f;
$grayMediumColor: #505050;
$grayColor: #727272;
$grayLightColor: #b0b0b0;
$grayLighterColor: #ccc;
$whiteColor: #fff;
$greenColor: #7A9E66;
$goldColor: #c98b25;
$sandGrayColor: #e1dbcb;
$redColor: #b00;
$redDarkColor: #6a0D24;
$purpleColor: #563d62;
$tealColor: #08A768;
$blueColor: #596D8D;
$blueDarkColor: #102257;
$orangeColor: #ffa713;
$yellowLightColor: #ffeccd;
$brandWhiteColor: #f5f5f5;

$brandPrimaryColor: $blackColor;
$brandSecondaryColor: $orangeColor;
$brandTertiaryColor: $brandWhiteColor;
$brandHighlightColor: $brandPrimaryColor;

$paypalcheckout: #ffc439;
$milesAndMoreColor: #071257;
$aboColor: #a47f42;
$aboBrownLightColor: #fef6e5;
$personalizationColor: #cdb998;

$moodOneColor: #6a0d24; // Diese Variablen bitte nicht mehr benutzen. Niemand weiß wozu diese Farben
$moodTwoColor: #6a0d24; // gut sind und was sich alles ändert, wenn man sie anpasst.
$moodThreeColor: #6a0d24;
$moodFourColor: #6a0d24;

$successBasketColor: $greenColor;

$availableColor: #417505;
$laterAvailableColor: #f5a623;
$soldOutColor: #d0021b;
$discountColor: #d0021b;
$dayOfferColor: $purpleColor;

// Basket
$basketGreenColor: #7a9e66;
$basketRedColor: #b00;
$basketPaypalColor: #eee;
$basketPaypalHoverColor: #ccc;

// Body
$bodyBackgroundColor: #f7f5f2;

// Status
$errorColor: $redColor;
$warningColor: #f6a445;
$waitingColor: $orangeColor;
$successColor: $greenColor;
$infoColor: $blueColor;

// Text
$textLightColor: $whiteColor;
$textLightGrayColor: $grayLightColor;
$textGrayColor: $grayDarkColor;
$textMediumGrayColor: $grayMediumColor;
$textBaseColor: $blackColor;
$textDarkestColor: $blackColor;
$textWarningColor: $warningColor;
$textErrorColor: $errorColor;
$textSuccessColor: $successColor;
$textInfoColor: $infoColor;
$textHoverColor: $brandPrimaryColor;

// Borders
$borderLightColor: $grayLighterColor;
$borderWhiteColor: #fff;
$borderDefaultColor: $blackColor;
$borderDarkColor: #aaa;
$borderBlackColor: #000;
$borderPrimaryColor: $grayMediumColor;
$borderAccountAddress: $grayColor;
$baseBorderRadius: 0;
$baseBorderRadiusSmall: 4px;

// Backgrounds
$backgroundWhiteColor: $whiteColor;
$backgroundGreenColor: $successColor;
$backgroundDarkColor: $blackColor;
$backgroundTableColor: $grayLightColor;
$backgroundWarningColor: $warningColor;
$backgroundGrayColor: $grayLighterColor;
$backgroundDarkGrayColor: $grayMediumColor;
$backgroundPrimaryColor: $grayLightColor;

// Badgets
$badgetBackgroundColor: $goldColor;
$badgetTextColor: $whiteColor;

// Remove
$removeColor: $blackColor;
$removeHoverColor: $textErrorColor;

// Fonts
$fontFamily: 'Raleway', 'Helvetica Neue', -apple-system, system-ui, sans-serif;
$fontPrimaryFamily: 'Raleway', 'Helvetica Neue', -apple-system, system-ui, sans-serif;
$fontHeadlineFamily: 'Raleway', 'Helvetica Neue', -apple-system, system-ui, sans-serif;
$fontSecondaryFamily: 'Raleway', 'Helvetica Neue', -apple-system, system-ui, sans-serif;

$fontLineHeightRegular: 1.5;

// Font weights
$fontWeightThin: 400;
$fontWeightRegular: 450;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;

// Font sizes
$fontSizeXXSmall: 12px;
$fontSizeXSmall: 13px;
$fontSizeSmall: 14px;
$baseFontSize: 16px;
$fontSizeBase: 16px;
$fontSizeFont: 16px;
$fontSizeMedium: 18px;
$fontSizeXMedium: 20px;
$fontSizeXYMedium: 20px;
$fontSizeXXMedium: 22px;
$fontSizeXXXMedium: 24px;
$fontSizeLarge: 24px;
$fontSizeXLarge: 26px;
$fontSizeXXLarge: 28px;
$fontSizeXXXLarge: 32px;
$fontSizeHuge: 68px;

/* stylelint-enable  plugin/no-unsupported-browser-features */
