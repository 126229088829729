.similar-products-list-page {
  margin: 16px auto;

  &__reference-product {
    width: 100%;
    max-width: 510px;
    margin: 8px auto 0;

    @media (min-width: $screen-mobile-large) {
      margin: 16px auto 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      max-width: none;
      margin: 0 0 16px;
    }
  }

  &__nav-desktop {
    display: none;

    &:empty {
      display: none;
    }

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      margin-top: 0;
    }

    @media (min-width: $screen-laptop) {
      margin-top: 8px;
    }
  }

  .products-list-page {
    &__name {
      margin: 15px 0;
      line-height: 1.4;
      font-size: 24px;

      @media (min-width: $screen-mobile) {
        font-size: 28px;
      }

      @media (min-width: $screen-tablet-portrait) {
        margin: 10px 0 15px;
        font-size: 33px;
      }

      @media (min-width: $screen-laptop) {
        font-size: 36px;
      }
    }

    &__top-bar {
      margin: 20px 0;
      justify-content: space-between !important;

      @media (min-width: $screen-tablet-portrait) {
        margin: 20px 0;
      }
    }
  }
}
