@import '../../../bps/components/filters/filters.scss';

.filters {
  $root: &;

  &__wrapper {
    &--mobile-active {
      .native-app & {
        top: $nativeAppHeaderHeight;
        bottom: $nativeAppBottomNavHeight;
        z-index: $indexPlpFiltersApp;
      }
    }
  }

  &__filter-toggle {
    @include iconFont(arrowDown) {
      position: absolute;
      top: 33%;
      transform: none;
      color: $filtersIconColor;
      transition: transform .2s;
      right: $filtersArrowRightMobile;
      font-size: $filtersArrowFontSize;
      font-weight: $filtersArrowFontWeight;

      @media (min-width: $screen-tablet-portrait) {
        right: $filtersArrowRightDesktop;
      }
    }

    &--active {
      &::before {
        transform: rotate(180deg);

        #{$root}__filter--switch & {
          display: none;
        }
      }
    }
  }

  &__products-sort {
    .select--formless .select__wrapper .select__selected-value {
      font-size: $fontSizeXSmall;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $fontSizeXXSmall;
      }
    }
  }

  &__list {
    padding: 55px 0 110px;

    .native-app & {
      padding: $nativeAppFilterListPadding;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding: 14px 0 75px;
    }
  }

  &__more-button {
    width: 100%;
    margin-right: 0;
    height: 54px;
    line-height: 52px;
    cursor: pointer;

    &:hover {
      box-shadow: inset 0 0 0 1px $blackColor;
    }
  }
}
