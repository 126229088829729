@import '../../../bps/components/sidebarMenu/sidebarMenu.scss';

.sidebar-menu {
  $root: &;

  &__content {
    @media (max-width: $screen-tablet-portrait - 1) {
      padding: 8px 16px;
    }
  }

  &__item {
    position: relative;
    color: $treeNavigationHighlightColor;
    font-size: $baseFontSize;
    font-weight: $treeNavigationFontWeight;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid $grayLightColor;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: $treeNavigationFontSize;
      border: 0 !important;
    }
  }

  &__link-addition,
  &__link {
    color: $treeNavigationHighlightColor;
    font-size: inherit;
    font-weight: $treeNavigationFontWeight;
    line-height: 1.85;
    padding: 10px 0;

    #{$root}__item--selected &,
    #{$root}__item--active & {
      color: $treeNavigationHighlightColor;
      font-weight: $treeNavigationFontWeightActive;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding: $treeNavigationItemPadding;
    }
  }

  &__sub-link {
    &--active {
      color: $treeNavigationHighlightColor;
      font-weight: $treeNavigationFontWeightActive;
    }
  }

  &--too-many {
    @include customScrollbar(4px);
    overflow-y: auto;
    max-height: 160px;
    padding-bottom: 20px;

    @media (min-width: $screen-tablet-portrait) {
      max-height: 444px;
    }
  }

  &__header {
    padding: 18px 16px;
  }
}

// Placeholder
.sidebar-menu--placeholder {
  height: 54px;
}
