.filters {
  $root: &;

  &__wrapper {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }

    &--mobile-active {
      z-index: $indexPlpFilters;
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $backgroundWhiteColor;

      @media (min-width: $screen-tablet-portrait) {
        position: static;
      }
    }
  }

  &__list {
    padding: 50px 0 75px;
    overflow-y: auto;
    max-height: 100%;
    background: $filtersListBackground;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      padding: 10px 0 20px;
      overflow-y: visible;
    }
  }

  &__mobile-toggles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__more-button,
  &__less-button,
  &__open-filters {
    width: 49%;
    margin-right: 1%;
    height: 60px;
    border: $filtersProductsSortBorder;
    text-transform: $filtersProductsSortTextTransform;
    font-size: $baseFontSize;
    text-align: left;
    line-height: 60px;
    padding: 0 15px;
    position: relative;
    background: $filtersMobileBackground;
    color: $filtersHeadlineMobileColor;

    @include iconFont(filter) {
      position: absolute;
      right: 15px;
      top: 50%;
      font-size: 10px;
      transform: translateY(-50%);
      color: $filtersHeadlineMobileColor;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__less-button,
  &__more-button {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      width: 100%;
      margin-right: 0;
      height: 54px;
      line-height: 52px;
      cursor: pointer;
      font-size: 14px;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: 16px;
    }
  }

  &__filter-button {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      padding: 20px 0;
    }

    &--hide {
      display: none;
    }
  }

  &__filter-more {
    &--hide {
      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }
  }

  &__products-sort {
    width: 49%;
    margin-left: 1%;
    height: 60px;
    border: $filtersProductsSortBorder;

    .select--formless .select__wrapper .select__selected-value {
      padding: 12px 16px 12px 6px;
      align-items: center;
      font-size: 12px;
    }
  }

  &__header {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  &__wrapper-bg {
    .filter--open & {
      @media (max-width: $screen-mobile-landscape-max) {
        position: absolute;
        height: 100%;
        z-index: 0;
        width: 100%;
        background: $filtersWrapperBg;
        touch-action: none; // stylelint-disable-line
      }
    }
  }

  &__header-mobile {
    display: block;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $backgroundWhiteColor;
    color: $filtersHeadlineMobileColor;
    z-index: 3;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / .1);

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__footer {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
    background: $backgroundWhiteColor;
    padding: 15px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / .1);

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__name {
    @include fontSize($fontSizeLarge);
  }

  &__mobile-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px 15px 18px 19px;

    @include iconFont(close) {
      font-size: 16px;
      color: $filtersHeadlineMobileColor;
    }

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__clear-all-button {
    width: 100%;
    height: 40px;
    background: $brandPrimaryColor;
    font-size: $baseFontSize;
    color: $textLightColor;
    text-align: center;
    position: relative;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include iconFont(close, after) {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
    }

    @media (min-width: $screen-tablet-portrait) {
      display: inline-block;
      width: auto;
      height: auto;
      padding: 0;
      background: none;
      color: $textMediumGrayColor;
      text-align: left;

      &::after {
        font-size: 10px;
        display: inline-block;
        margin-left: 10px;
        position: relative;
        transform: none;
        right: auto;
        top: auto;
      }
    }

    @media (min-width: $screen-desktop-sm) {
      &:hover {
        color: $textDarkestColor;
      }
    }
  }

  &__filter {
    border-top: $filtersFilterBorderTop;

    &--disabled {
      display: none;
    }
  }

  &__filter-toggle {
    background: none;
    border: none;
    font-size: $filtersFontSize;
    font-weight: $filtersFontWeight;
    display: block;
    width: 100%;
    padding: $filtersPaddingSpacing;
    text-align: left;
    position: relative;
    cursor: pointer;
    color: $filtersColor;
    text-transform: $filtersTextTransform;
    letter-spacing: $filtersLetterSpacing;

    @media (min-width: $screen-tablet-portrait) {
      padding: $filtersPaddingSpacing $filtersPaddingSpacing $filtersPaddingSpacing 0;
    }

    &:active,
    &:focus {
      outline: none;
    }

    &:focus-visible { // stylelint-disable-line
      outline: 2px solid $textDarkestColor;
    }

    @include iconFont(arrowRight) {
      position: absolute;
      top: 50%;
      color: $filtersIconColor;
      transition: transform .2s, opacity .2s;
      right: $filtersArrowRightMobile;
      transform: translateY(-50%) rotate(90deg);
      font-size: $filtersArrowFontSize;
      font-weight: $filtersArrowFontWeight;

      @media (min-width: $screen-tablet-portrait) {
        right: $filtersArrowRightDesktop;
      }
    }

    &--active {
      &::before {
        transform: translateY(-50%) rotate(270deg);
      }
    }

    &--placeholder {
      display: block !important;
    }

    #{$root}__filter--switch & {
      display: none;
    }
  }

  &__filter-dropdown {
    display: none;
    padding: 0 15px;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
    }

    &--visible {
      display: block;
    }

    &--price {
      margin-bottom: 20px;
    }
  }

  &__options {
    display: none;
    padding: 0 15px;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
    }

    &::before {
      content: '';
      width: 100%;
      height: 20px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      background: linear-gradient(to bottom, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 1) 90%); // stylelint-disable-line plugin/no-unsupported-browser-features
      z-index: 3;
    }

    &--visible {
      display: block;
    }
  }

  &__line-placeholder {
    @include componentPreloader() {
      width: 100%;
      height: 22px;
      line-height: 22px;
      display: block;
      margin-bottom: 10px;
      color: $grayColor;
      padding-left: 30px;
      text-decoration: none;
    }
  }

  .input__wrapper {
    &:hover,
    &--focus {
      border-color: $filtersInputFocusBorderColor;
    }
  }

  .loader {
    pointer-events: none;
    background: $filtersLoaderBackground;

    &::before {
      color: $filtersLoaderColor;
    }

    & > * {
      opacity: $filtersLoaderOpacity;
    }
  }
}
