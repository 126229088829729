@import '../../../bps/components/productsListPage/productsListPage.scss';

.products-list-page {
  .carousel {
    .carousel__button {
      &--prev {
        left: -15px;

        @media (min-width: $screen-tablet-landscape) {
          left: -20px;
        }
      }

      &--next {
        right: -15px;

        @media (min-width: $screen-tablet-landscape) {
          right: -20px;
        }
      }
    }
  }

  &__hero-teaser {
    .image-text-action-teaser__img {
      position: relative;
      display: block;
      z-index: 0;
      width: 100%;
      height: 40vw;
      overflow: hidden;
      min-height: 300px;
      max-height: 340px;

      @media (min-width: $screen-mobile-large) {
        min-height: 380px;
        max-height: 380px;
      }

      @media (min-width: $screen-tablet-portrait) {
        height: 37vw;
        min-height: 280px;
        max-height: 340px;
      }

      @media (min-width: $screen-tablet-landscape) {
        max-height: 380px;
      }

      @media (min-width: $screen-desktop) {
        height: 40vw;
        min-height: 400px;
        max-height: 450px;
      }

      @media (min-width: $screen-tv) {
        max-width: 2560px;
      }
    }

    .image-element__pic {
      margin: auto;
    }

    .image-element__img {
      height: 100%;
      width: auto;
      position: absolute;
      z-index: 1;
      margin: auto;
      top: -6666px;
      left: -6666px;
      right: -6666px;
      bottom: -6666px;
    }
  }

  .product-content-block {
    &__images {
      flex-direction: column;
    }

    p {
      @include fontSize($baseFontSize);
      margin: 10px 0;
      color: $productContentBlockColor;
    }

    a {
      color: $productContentBlockColor;
    }
  }

  .scroll-to-content-button {
    @include fontSize(18px);
    font-weight: $fontWeightThin;
  }

  .product-content-block--text-block-left {
    .product-list-content-block--images .product-content-block__wrapper-image {
      @media (min-width: $screen-tablet-portrait) {
        width: 70%;
        padding: 0 0 0 40px;
      }
    }

    .carousel {
      .carousel__button {
        &--prev {
          @media (min-width: $screen-tablet-portrait) {
            left: -35px;
          }

          @media (min-width: $screen-desktop-xl) {
            left: -50px;
          }
        }

        &--next {
          @media (min-width: $screen-tablet-portrait) {
            right: -35px;
          }

          @media (min-width: $screen-desktop-xl) {
            right: -50px;
          }
        }
      }
    }
  }

  &__intro-text {
    padding-bottom: 15px;
    position: relative;

    .scroll-to-content-button {
      position: absolute;
      bottom: 0;
    }
  }

  &__top-bar--has-pages-count {
    min-height: 50px;

    @media (min-width: $screen-tablet-portrait) {
      min-height: 48px;
    }
  }
}
