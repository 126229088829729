.product-list-content-block {
  line-height: 1.3;

  &__teaser {
    margin: 16px 0 0;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      margin: 24px 0 0;
    }
  }

  &__subtitle {
    @include fontSize($fontSizeXXMedium);
    margin: 8px 0;
    font-weight: $fontWeightMedium;
    font-family: $fontSecondaryFamily;
  }

  .scroll-to-content-button {
    @include fontSize(18px);
    margin-top: -8px;
    display: block;
    font-weight: $fontWeightThin;

    &::after {
      display: none;
    }
  }

  &__description {
    margin-bottom: 16px;

    a {
      color: $textDarkestColor;

      &:hover {
        color: $textHoverColor;
        text-decoration-color: $textHoverColor;
      }
    }
  }

  .mood-teaser__picture-wrap {
    overflow: visible;
  }

  .read-more--closed {
    .read-more__container {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 24px;
        background: linear-gradient(to top, $bodyBackgroundColor, transparentize($bodyBackgroundColor, 1)); // stylelint-disable-line  plugin/no-unsupported-browser-features
      }
    }
  }

  .product-content-block {
    &__wrapper-text {
      width: 100%;
    }

    &__headline {
      display: none;
    }
  }
}
