@import '../../../bps/components/productsList/productsList.scss';

.products-list {
  $root: &;

  &__element {
    &--type {
      &_insertion {
        padding: 0;
        border-top: 0 none;
        border-bottom: 0 none;
      }

      &_product {
        .products-list-page:not(.products-list-page--no-sidebar) & {
          @media (min-width: $screen-tablet-landscape) and (max-width: 1199px) {
            width: 50%;
          }
        }

        .products-list-page.products-list-page--no-sidebar & {
          @media (min-width: $screen-tablet-xsm) {
            width: 50%;
          }

          @media (min-width: $screen-tablet-landscape) {
            width: calc(100% / 3);
          }

          @media (min-width: $screen-desktop) {
            width: 25%;
          }
        }
      }
    }
  }

  .product-box {
    &__price-wrapper {
      #{$root}--type-vertical & {
        width: 100%;
        min-height: 172px;

        @media (min-width: $screen-tablet-portrait) {
          min-height: 176px;
        }

        @media (min-width: $screen-tablet-landscape) {
          min-height: 159px;
        }
      }
    }
  }
}
