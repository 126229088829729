.filter {
  $root: &;

  &__options {
    @include customScrollbar(4px);
    overflow-y: auto;
    max-height: 160px;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  &__option {
    display: block;
    position: relative;
    padding: $filterOptionPadding;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__input {
    appearance: none; // stylelint-disable-line plugin/no-unsupported-browser-features
    display: block;
    width: 22px;
    height: 22px;
    background: $filterBackgroundColor;
    border: $filterBoxBorder;
    position: absolute;
    left: 0;
    top: 0;

    &:checked {
      border-color: $filterCheckboxActiveColor;

      @include iconFont(checkSmall) {
        font-size: 10px;
        color: $filterCheckboxActiveColor;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      + #{$root}__label {
        font-weight: $fontWeightSemiBold;
      }
    }
  }

  &__label {
    font-size: $filterLabelFontSize;
    line-height: 1.3;
    color: $filterColor;
    text-decoration: none;
  }

  &__option-product-count {
    color: $filterCountColor;
  }

  &__search {
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    .input__wrapper {
      &::after {
        display: none;
      }

      .input__field {
        padding: 7px 11px;
      }
    }

    .input__button {
      color: $brandPrimaryColor;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &--switch {
    #{$root}__option {
      display: flex;
      padding: 0;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    #{$root}__options {
      padding: 0;
      margin: 16px 0 13px;
    }

    #{$root}__input {
      display: inherit;
      border-width: 0 !important;
    }

    #{$root}__label {
      padding: 0 12px 4px 0;
    }

    input[type="checkbox"] {
      position: relative;
      appearance: none; // stylelint-disable-line plugin/no-unsupported-browser-features
      clip-path: none;  // stylelint-disable-line plugin/no-unsupported-browser-features
      clip: auto;
      width: $filterSwitchWidth;
      height: $filterSwitchHeight;
      background: $filterSwitchColor;
      border-radius: $filterSwitchHeight;
      box-shadow: $filterSwitchShadow;
      cursor: pointer;
      transition: .4s;
      order: 2;
      margin: 4px;

      &::after {
        position: absolute;
        content: "";
        width: $filterSwitchHeight;
        height: $filterSwitchHeight;
        top: 0;
        left: 0;
        background: $filterSwitchBackgroundColor;
        border-radius: 50%;
        box-shadow: $filterSwitchToggleShadow;
        transition: .4s;
      }
    }

    input:checked[type="checkbox"] {
      background: $filterSwitchActiveColor;

      &::after {
        left: calc(50% + 2px);
      }

      &::before {
        display: none;
      }
    }
  }

  &--open {
    @media (max-width: $screen-mobile-landscape-max) {
      overflow: hidden;
    }
  }
}
