.filter-price {
  $root: &;
  position: relative;

  &__unit {
    position: absolute;
    top: -28px;
    right: 30px;
    width: 100%;
    display: block;
    text-align: right;
    color: $filterPriceUnitColor;
    font-size: $fontSizeXSmall;
    pointer-events: none;
  }

  &__list {
    display: none;
    margin-bottom: 20px;
  }

  &__option {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 6px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__option-box {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid $borderDefaultColor;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 1px;
  }

  &__option-input {
    display: none;

    &:checked {
      + #{$root}__option-box {
        border-color: $brandPrimaryColor;

        &::before {
          content: '';
          border-radius: 50%;
          width: 8px;
          height: 8px;
          display: block;
          background: $brandPrimaryColor;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        + #{$root}__label {
          font-weight: $fontWeightSemiBold;
        }
      }
    }
  }

  &__option-label {
    font-size: 14px;
    line-height: 1.3;
  }

  &__option-product-count {
    color: $textMediumGrayColor;
  }

  &.loader {
    pointer-events: none;

    & > * {
      opacity: .5;
    }
  }
}

// Placeholder
[data-placeholder='filter-price'] {
  @include componentPreloader();
  display: block;
  min-height: 82px;
}
