.products-list-page {
  @include sideColumnLayout();
  position: relative;

  .product-list--max-list-columns-4 & {
    max-width: $maxWidthProductlist;
  }

  &__loader {
    background: rgb(255 255 255 / .5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    &--hidden {
      display: none;
    }
  }

  &__column {
    display: block;
  }

  &__name {
    margin: $productListPageNameMargin;
    text-align: left;
    padding: 0;
    text-transform: none;
    line-height: 1.2;

    .page-intro--mood & {
      color: $moodTeaserHeadlineColor;
    }
  }

  &__sidebar-menu {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      margin-bottom: 25px;
    }
  }

  &__sidebar-menu-list {
    margin: 15px 0 25px;
  }

  &__sidebar-teasers {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  &__top-bar {
    margin: 20px 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: space-between;
      margin: 50px 0 20px;
      min-height: 45px;
    }

    &--empty {
      @media (min-width: $screen-tablet-portrait) {
        display: none !important;
      }
    }

    &--no-sort {
      justify-content: flex-end !important;
    }
  }

  &__pagination {
    min-height: 50px;

    @media (min-width: $screen-tablet-portrait) {
      min-height: 48px;
    }
  }

  &__bottom-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px 0 20px;

    .pagination {
      width: 100%;
    }
  }

  &__products-sort {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      min-width: 200px;
      min-height: 48px;
    }
  }

  &__active-filters {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $borderLightColor;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 20px;
      border-bottom: 0;
    }
  }

  &__hero-teaser {
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 24px;
    }
  }

  &__insertion {
    width: 100%;

    &--top {
      margin: 24px 0;
    }

    &--bottom {
      margin-top: 32px;
      padding-left: 0;
      padding-right: 0;
    }

    & + .category-content {
      margin-top: 32px;
    }
  }

  &__last-search-terms {
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }

  &__content-search-results {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $borderDefaultColor;
  }

  &__intro-image {
    padding: 0 0 20px;

    img {
      max-width: 100%;
    }
  }

  .category-content {
    width: 100%;
  }

  .select {
    &__selected-value {
      color: $productsSortSelectColor;
    }

    &__wrapper--active .select__selected-value {
      color: $selectActiveColor;
    }
  }

  &__navigation {
    padding: $productsListPageNavigationPadding;
  }

  &__filters {
    padding: $productsListPageFiltersPadding;
  }

  &__origins {
    margin: 0 0 24px;
  }

  &__dropdown-nav.dropdown-navigation {
    margin: 16px 0;
  }

  &__dropdown-nav,
  .sibling-navigation {
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__tree-nav {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  &__skip-to-content-link {
    position: absolute;
    height: 0;
    opacity: 0;

    &:focus-visible { // stylelint-disable-line
      position: relative;
      height: auto;
      opacity: 1;
    }
  }

  &__anchor {
    position: absolute;
    top: $productsListInspirationTabAnchorTopM;

    @media (min-width: $screen-tablet-portrait) {
      top: $productsListInspirationTabAnchorTopD;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
products-list, [is="products-list"] {
  .slot-insertion {
    display: none;
  }
}

/* stylelint-disable-next-line */
product-list-pagination, [is="product-list-pagination"] {
  &.products-list-page__pagination {
    min-height: 0;
  }
}
